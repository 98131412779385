<template>
  <!-- 
    Stepper에서 제외할 항목
    CSE0000002 : 신청중
    CSE0000003 : 신청완료
    CSE0000004 : 대여중
    CSE0000005 : 반납완료
  -->
      <c-step
        stepperGrpCd="HEA_CLOSED_SPACE_EQUIP_STEP_CD"
        :param="param"
        :currentStepCd="requestStepCd"
        :stepRejectItems="stepRejectItems"
        v-model="requestStepCd"
        @getDetail="getDetail"
        @setHeaClosedSpaceEquipmentGiveId="setHeaClosedSpaceEquipmentGiveId"
        @closePopup="closePopup"
      >
      </c-step>
    </template>
    <script>
    import selectConfig from '@/js/selectConfig';
    export default {
      name: 'closedSpaceEquipDetail',
      props: {
        popupParam: {
          type: Object,
          default: () => ({
            heaClosedSpaceEquipmentGiveId: '',
          }),
        },
      },
      data() {
        return {
          giveEquipment: {
          heaClosedSpaceEquipmentGiveId: '',
          plantCd: '',
          requestDate: '',
          regUserId: '',
          spaceNameCd: null,
          spaceNameName: '',
          giveStatusCd: '',
          requestStepCd: '',
          closedSpaceEquipmentGiveItemModels: [],
          deleteClosedSpaceEquipmentGiveItemModels: [],
          closedSpaceEquipmentReturnItemModels: [],
          deleteClosedSpaceEquipmentReturnItemModels: [],
          },
          requestStepCd: 'CSE0000001',
          editable: true,
        };
      },
      watch: {
      },
      computed: {   //computed는 setter가 없으니 지정해줄 수 없음
        param() {
          return {
            heaClosedSpaceEquipmentGiveId: this.popupParam.heaClosedSpaceEquipmentGiveId,
            giveEquipment: this.giveEquipment,
          }
        },
        stepRejectItems() {
        let rejectItems = ['CSE0000002', 'CSE0000003', 'CSE0000004', 'CSE0000005'];
        return rejectItems;
        },
        isOld() {
          return Boolean(this.popupParam.heaClosedSpaceEquipmentGiveId)
        },
      },
      beforeCreate() {},
      created() {},
      beforeMount() {
        Object.assign(this.$data, this.$options.data());
      },
      mounted() {
        this.init();
      },
      beforeDestroy() {
      },
      methods: {
        init() {
          // role setting
          this.editable = this.$route.meta.editable;
          // url setting
          this.detailUrl = selectConfig.hea.cse.gives.get.url;
          // code setting
          // list setting
          this.getDetail();
        },
        getDetail() {
          if (this.popupParam.heaClosedSpaceEquipmentGiveId) {
            this.$http.url = this.$format(this.detailUrl, this.popupParam.heaClosedSpaceEquipmentGiveId);
            this.$http.type = 'GET'; 
            this.$http.request((_result) => {
              this.$_.extend(this.giveEquipment, _result.data);
              if (this.giveEquipment.closedSpaceEquipmentGiveItemModels && this.giveEquipment.closedSpaceEquipmentGiveItemModels.length > 0) {
                this.$_.forEach(this.giveEquipment.closedSpaceEquipmentGiveItemModels, item => {
                  item.rentPeriod = [item.rentStartDate, item.rentEndDate]
                })
              }
            
              if (this.giveEquipment.closedSpaceEquipmentReturnItemModels && this.giveEquipment.closedSpaceEquipmentReturnItemModels.length > 0) {
                this.$_.forEach(this.giveEquipment.closedSpaceEquipmentReturnItemModels, item => {
                  item.rentPeriod = [item.rentStartDate, item.rentEndDate]
                })
              }
              // 상세팝업 최초작성/최근수정 정보 노출
              this.$emit('setRegInfo', _result.data)
              this.setStep();
            },);
          } else {
            this.giveEquipment.regUserId = this.$store.getters.user.userId;
            // nothing...
          }
        },
        setHeaClosedSpaceEquipmentGiveId(heaClosedSpaceEquipmentGiveId) {
          this.$set(this.popupParam, 'heaClosedSpaceEquipmentGiveId', heaClosedSpaceEquipmentGiveId);
          this.getDetail();
        },
        setStep() {
          this.requestStepCd = this.giveEquipment.requestStepCd;
          if (!this.isOld) {
            this.requestStepCd = 'CSE0000001';
          } else if (this.giveEquipment.requestStepCd === 'CSE0000006') {
            this.requestStepCd = 'CSE0000006';
          } 
        },
        closePopup() {
          this.$emit('closePopup')
        },
      }
    };
    </script>
    