var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("c-step", {
    attrs: {
      stepperGrpCd: "HEA_CLOSED_SPACE_EQUIP_STEP_CD",
      param: _vm.param,
      currentStepCd: _vm.requestStepCd,
      stepRejectItems: _vm.stepRejectItems,
    },
    on: {
      getDetail: _vm.getDetail,
      setHeaClosedSpaceEquipmentGiveId: _vm.setHeaClosedSpaceEquipmentGiveId,
      closePopup: _vm.closePopup,
    },
    model: {
      value: _vm.requestStepCd,
      callback: function ($$v) {
        _vm.requestStepCd = $$v
      },
      expression: "requestStepCd",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }